import axios from 'axios';
import { Loading } from 'element-ui';
// 全局axios请求loading加载
let options = {
	lock: true,
	text: '',
	spinner: 'el-icon-loading',
	background: 'rgba(0, 0, 0, 0.7)'
},
	loadingPage

const BASE_URL = "https://kl13xpf.zichushi.cn"
let headers = {
	Accept: 'application/json;charset=utf-8',
	'Content-Type': 'application/json;charset=utf-8'
}
// axios配置参数
axios.defaults.baseURL = BASE_URL
axios.defaults.headers = headers
axios.defaults.timeout = 15000
axios.defaults.withCredentials = true; // 启用跨域支持


// 请求拦截器
axios.interceptors.request.use(config => {
	loadingPage = Loading.service(options);
	return config
},
	error => {
		loadingPage.close()
		return Promise.reject(error)
	}
)

//  响应拦截器
axios.interceptors.response.use(response => {
	loadingPage.close()
	if (response.data.code === 200) {
		return response.data
	}
	return response.data
},
	error => {
		loadingPage.close()
		if (error.response.data.code === 500) {
			console.log(error.response.data)
		}

	}
)

export default axios