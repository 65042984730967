import httpUtil from '../utils/httpUtil'

/*查询全校职工数量*/
export function AllSchoolTeacher(){
    return httpUtil.get("/kx/data/select/selectTeacherNum")
}
export function AllStudent(){
    return httpUtil.get("/kx/data/select/ratioOfMenToWomen")
}
/*全学期*/
export function AllSemester(){
    return httpUtil.post("/kx/timing/list")
}
export function GetSemester(){
    return httpUtil.get("/kx/data/timing/now")
}
/*所有班级*/
export function GetAllClass(){
    return httpUtil.get("/api/classes/list")
}
/*获取ABC班级*/
export function GetABCScore(classId,timingId,type,rateId){
    return httpUtil.get("/kx/data/class/avRateScoreABC/"+classId+"/"+timingId+"/"+type+"/"+rateId)
}

export function GetClassScore(classId,timingId){
    return httpUtil.get("/kx/data/class/avMark/"+classId+"/"+timingId)
}

export function GetClassStudent(classId,timingId){
    return httpUtil.get("/kx/data/student/"+classId+"/"+timingId)
}

export function GetStudentinfo(studentId){
    return httpUtil.get("/kx/data/class/student/"+studentId)
}

export function GetStudentScore(studentId,timingId){
    return httpUtil.get("/kx/data/student/Mark/"+studentId+"/"+timingId)
}
